.PageDisponibilites {
  display: flex;
  flex-direction: row;
  padding: 30px;
  gap: 40px;
  background-color: #a8bea530;
  padding-bottom: 100px;
}

.BlocTexte {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BlocCalendriers {
  flex: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  align-items: flex-start;
  justify-content: space-evenly;
}

.TexteReservation {
  margin: 30px 0px;
  text-align: center;
  font-size: 18px;
  color: #295c3e;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageDisponibilites {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 40px;
    padding-bottom: 200px;
  }
}
