.PageServices {
  background-color: #b1d0d140;
  padding: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  align-items: flex-start;
  justify-content: center;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageServices {
    background-color: #b1d0d140;
    padding: 60px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    align-items: flex-start;
    justify-content: center;
  }
}
