.Calendrier {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  margin-bottom: 20px;
}

.Grille {
  display: grid;
  grid-template-columns: repeat(7, 40px);
  grid-auto-rows: 40px;
  column-gap: 10px;
  row-gap: 10px;
}

.NomJour {
  font-size: 16px;
  color: #295c3e;
  text-align: center;
}

.JourDisponible {
  border: 1px solid #295c3e;
  color: #295c3e;
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.JourNonDisponible {
  border: none;
  color: #97989d;
  text-align: center;
  vertical-align: middle;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
}
