.PageChambre {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  padding: 40px;
}

.Description {
  flex: 1;
  text-align: center;
}

.Galerie {
  flex: 1;
  width: 50%;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageChambre {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    padding: 20px;
  }

  .Galerie {
    flex: 1;
    width: 100%;
  }
}
