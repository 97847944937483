.PageHome {
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 50px;
}

.BlocHome {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 50px;
  box-sizing: border-box;
}

.Photo {
  max-width: 45%;
  flex: 1;
}

.TexteAccueil {
  flex: 1;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageHome {
    display: flex;
    flex-direction: column;
    padding: 0px;
    gap: 50px;
  }

  .BlocHome {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    gap: 50px;
    box-sizing: border-box;
    padding: 20px;
  }

  .Photo {
    max-width: 100%;
    flex: 1;
  }
}
