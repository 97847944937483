.BarreNavigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 00px;
  margin: 0px 40px;
  font-family: "Satisfy", cursive;
  font-size: 20px;
  color: #2b5a3c;
  border-bottom: #aabda7 1px solid;
}

.Liens {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-right: 100px;
  flex-wrap: wrap;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .BarreNavigation {
    display: none;
  }
}
