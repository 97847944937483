.Entete {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding: 0px 40px;
  background-color: #2a5b3e;
  height: 50px;
  color: white;
  gap: 40px;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .Entete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: right;
    padding: 20px 40px;
    background-color: #2a5b3e;
    min-height: 60px;
    color: white;
    gap: 10px;
  }
}
