body {
  width: auto;
  margin: 0;
  padding: 0;
  background-color: #a7c0a3;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

p {
  color: #67848a;
  text-align: justify;
}

h1 {
  font-family: "Passions Conflict", cursive;
  font-size: 100px;
  font-style: ;
  color: #67848b;
  letter-spacing: 0;
  font-weight: 100;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 40px;
  color: #67848b;
  letter-spacing: 0;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

h3 {
  font-family: "Satisfy", cursive;
  font-size: 40px;
  color: #678588;
  letter-spacing: 0;
  font-weight: 300;
}

h4 {
  font-family: "Satisfy", cursive;
  font-size: 30px;
  color: #2a5b3e;
  letter-spacing: 0;
  text-align: center;
  font-weight: 300;
}

h5 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: white;
  letter-spacing: 0;
  text-transform: capitalize;
  margin: 20px;
  padding: 0;
  background-color: #295c3e;
  width: 100%;
  text-align: center;
  padding: 5px;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin: 10px;
}

.App {
  max-width: 1200px;
  background-color: white;
  margin: auto;
  box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.5);
}

.Signature {
  font-family: "Satisfy", cursive;
  font-size: 30px;
  color: #66858a;
  text-align: right;
  padding-right: 80px;
  margin: 0;
  font-weight: 300;
  font-weight: 300;
  font-style: normal;
}

.Description {
  font-size: 14px;
  letter-spacing: 0;
  margin: 0;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  h1 {
    font-family: "Passions Conflict", cursive;
    font-size: 60px;
    color: #67848b;
    letter-spacing: 0;
    font-weight: 100;
    margin: 0;
    padding: 0;
  }

  h2 {
    font-size: 30px;
    color: #67848b;
    letter-spacing: 0;
    font-weight: 300;
    margin: 0;
    padding: 0;
  }
}
