.PhotosHome {
  max-width: 800px;
  height: auto;
  position: relative;
  box-sizing: border-box;
  transform: rotate(-2deg);
}

.PhotoMaison {
  border: 11px solid #ffffff;
  box-shadow: 8px 8px 20px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  max-width: 100%;
  transform: rotate(-2deg);
  animation: 0.5s slidein;
}

.CartePostale {
  max-width: 60%;
  transform: rotate(-10deg);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 3%;
  left: 2%;
  animation: 1s slidein2;
}

@keyframes slidein {
  from {
    transform: scale(1.05) rotate(-3deg);
  }
  to {
    transform: scale(1) rotate(-2deg);
  }
}
@keyframes slidein2 {
  from {
    transform: scale(1.1) rotate(-6deg);
  }
  to {
    transform: scale(1) rotate(-10deg);
  }
}
