.PageTarifs {
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
}
.Liste {
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.Ligne {
  display: flex;
  flex-direction: row;
  gap: 30px;
  box-sizing: border-box;
  min-height: 40px;
  padding: 10px 0px;
}
.Service {
  flex: 1;
  text-align: right;
}

.Tarif {
  width: 100%;
  flex: 1;
  font-weight: bold;
  color: #67848b;
}

.ModesAcceptes {
  text-align: center;
  font-weight: bold;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #67848b;
}

/********* MOBILE *************/
@media screen and (max-width: 820px) {
  .PageTarifs {
    width: 100%;
    padding: 40px 20px;
    box-sizing: border-box;
  }

  .Ligne {
    display: flex;
    flex-direction: row;
    gap: 30px;
    box-sizing: border-box;
    min-height: 40px;
    padding: 10px 0px;
    border-top: 1px solid #ddd;
  }
  .Service {
    flex: 1;
    text-align: left;
  }

  .Tarif {
    width: 100%;
    flex: 1;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #67848b;
  }
}
